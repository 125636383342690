<template>
    <div>
        <div class="top">
            <div class="title">发票管理</div>
            <div class="policy" @click="handleEditInvoice">新建发票信息</div>
        </div>
        <div class="invoice">
            <div
                class="invoice-list"
                v-for="(item, index) in list"
                :key="index"
            >
                <div
                    v-if="item.InvoiceType == 1 || item.InvoiceType == 2"
                    class="payment-list-button"
                >
                    <div class="invoice-list-main">
                        <div>
                            <div class="lable">
                                <p v-if="item.InvoiceType == 1">
                                    增值税专用发票
                                </p>
                                <p v-else>增值税普通发票</p>
                            </div>
                            <div class="lable">
                                发票抬头 : {{ item.InvoiceTitle }}
                            </div>
                            <div class="lable">
                                企业税号 : {{ item.DutyParagraph }}
                            </div>
                            <div class="lable">注册电话 : {{ item.Phone }}</div>
                            <div class="lable">
                                注册地址 : {{ item.Address }}
                            </div>
                            <div class="lable">
                                开户银行 : {{ item.BankName }}
                            </div>
                            <div class="lable">
                                银行帐号 : {{ item.BankNumber }}
                            </div>
                        </div>
                        <div class="invoice-list-right">
                            <div
                                v-if="item.IsDefault || index == 0"
                                class="active"
                            >
                                当前默认
                            </div>
                            <div v-else @click="handleDefault(item)">
                                设为默认
                            </div>
                            <div @click="handleEditInvoice1(item)">
                                修改
                            </div>
                            <div @click="del(item)">删除</div>
                        </div>
                    </div>
                </div>
                <div v-if="item.InvoiceType == 3" class="payment-list-info">
                    <div class="invoice-list-main">
                        <div>
                            <div class="lable">
                                普通电子发票 - 个人 <span>发票抬头: {{item.InvoiceTitle}}</span>
                                <span>发票内容: 商品明细</span>
                            </div>
                        </div>
                        <div class="invoice-list-right">
                            <div
                                v-if="item.IsDefault || index == 0"
                                class="active"
                            >
                                当前默认
                            </div>
                            <div v-else @click="handleDefault(item)">
                                设为默认
                            </div>
                            <div @click="handleEditInvoice1(item)">
                                修改
                            </div>
                            <div @click="del(item)">删除</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="发票信息"
            class="invoice-dialog"
            :visible.sync="dialogVisible"
            width="500px"
        >
            <div>
                <el-radio-group v-model="form.radio" size="small">
                    <el-radio
                        v-for="(item, index) in invoiceList"
                        :key="index"
                        :label="item.label"
                        border
                        :disabled="item.disabled"
                        >{{ item.title }}
                    </el-radio>
                </el-radio-group>
            </div>
            <div class="invoice-dialog-main">
                <div>
                    <el-form
                        ref="ruleForm"
                        :rules="rules"
                        :model="form"
                        label-width="120px"
                    >
                        <el-form-item label="发票抬头">
                            <el-radio-group
                                v-model="form.InvoiceType"
                                size="small"
                                @change="changeRise"
                            >
                                <el-radio :label="2" border>单位</el-radio>
                                <el-radio
                                    :label="3"
                                    border
                                    v-if="form.radio == 2"
                                    >个人</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                        <!--个人-->
                        <el-form-item
                            label="个人名称"
                            v-if="form.InvoiceType == 3"
                            prop="InvoiceTitle"
                        >
                            <el-input
                                style="width: 300px"
                                size="small"
                                v-model="form.InvoiceTitle"
                            ></el-input>
                        </el-form-item>
                        <!--公司-->
                        <div
                            v-if="
                                form.InvoiceType == 2 || form.InvoiceType == 1
                            "
                        >
                            <el-form-item label="单位名称" prop="InvoiceTitle">
                                <el-autocomplete
                                    class="inline-input"
                                    :disabled="disabled"
                                    v-model="form.InvoiceTitle"
                                    :fetch-suggestions="querySearch"
                                    placeholder="请输入内容"
                                    size="small"
                                    style="width: 300px"
                                    :trigger-on-focus="false"
                                    @select="handleSelect"
                                ></el-autocomplete>
                            </el-form-item>
                            <el-form-item
                                label="纳税人识别号"
                                prop="DutyParagraph"
                            >
                                <el-input
                                    :disabled="disabled"
                                    style="width: 300px"
                                    v-model="form.DutyParagraph"
                                    size="small"
                                    placeholder="请输入纳税人识别号"
                                ></el-input>
                            </el-form-item>

                            <el-form-item label="单位地址" prop="Address">
                                <el-input
                                    style="width: 300px"
                                    v-model="form.Address"
                                    size="small"
                                    placeholder="请输入单位地址"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="单位电话" prop="Phone">
                                <el-input
                                    style="width: 300px"
                                    v-model="form.Phone"
                                    size="small"
                                    placeholder="请输入单位电话"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="开户银行" prop="BankName">
                                <el-input
                                    style="width: 300px"
                                    v-model="form.BankName"
                                    size="small"
                                    placeholder="请输入开户银行"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="银行账号" prop="BankNumber">
                                <el-input
                                    style="width: 300px"
                                    v-model="form.BankNumber"
                                    size="small"
                                    placeholder="请输入银行账号"
                                ></el-input>
                            </el-form-item>
                        </div>
                        <el-form-item label="发票内容">
                            <el-radio-group v-model="radio2" size="small">
                                <el-radio :label="1" border>商品明细</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="">
                            <el-button
                                class="ruleForm"
                                size="mini"
                                @click="submitForm('ruleForm')"
                                >保存</el-button
                            >
                            <el-button
                                size="mini"
                                @click="dialogVisible = false"
                                >取消</el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { changedInvoice, getUserInvoice } from '../settlement/service'

import {
    removeInvoice,
    searchCompanyName,
    getCompanyDetailInfo
} from './service'

export default {
    data() {
        return {
            dialogVisible: false,
            form: {
                radio: 2,
                InvoiceType: 2
            },
            disabled: false,
            rules: {
                InvoiceTitle: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    }
                ],
                DutyParagraph: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    }
                ],
                Address: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    }
                ],
                Phone: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    }
                ],
                BankName: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    }
                ],
                BankNumber: [
                    {
                        required: true,
                        message: '请输入内容',
                        trigger: 'change'
                    },
                    {
                        min: 8,
                        max: 30,
                        message: '长度在 8 到 30 个字符',
                        trigger: 'blur'
                    }
                ]
            },
            invoice: null,
            radio2: 1,
            invoiceList: [
                {
                    title: '增值税专用发票',
                    label: 1,
                    disabled: false
                },
                {
                    title: '增值税普通发票',
                    label: 2,
                    disabled: false
                }
            ],
            list: []
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        changeRise(value) {
            // 单位点击
            if (value == 2) {
                this.form.InvoiceTitle = this.InvoiceTitle
                this.invoiceList[0].disabled = false
            } else {
                this.InvoiceTitle = this.form.InvoiceTitle
                this.form.InvoiceTitle = ''
                this.invoiceList[0].disabled = true
            }
        },
        handleEditInvoice() {
            this.form = {
                radio: 2,
                InvoiceTitle: '',
                InvoiceType: 2
            }
            this.dialogVisible = true
            this.disabled = false
        },
        querySearch(queryString, cb) {
            if (queryString != '') {
                searchCompanyName({ name: queryString }).then(res => {
                    for (const key in res.data) {
                        res.data[key].value = res.data[key].name
                    }
                    cb(res.data)
                })
            }
            // var restaurants = this.restaurants
            // var results = queryString
            //     ? restaurants.filter(this.createFilter(queryString))
            //     : restaurants
            // // 调用 callback 返回建议列表的数据
            // cb(results)
        },
        handleSelect(item) {
            getCompanyDetailInfo({ ID: item.id }).then(res => {
                this.form = {
                    ...this.form,
                    DutyParagraph: res.data.taxNumber,
                    Address: res.data.regLocation,
                    Phone: res.data.phoneNumber
                }
            })
            this.disabled = true
        },
        handleEditInvoice1(item) {
            this.radio2 = 1
            if (item.InvoiceType == 1) {
                // 专用发票
                this.form = {
                    ...item,
                    radio: 1,
                    InvoiceType: 2
                }
            } else if (item.InvoiceType == 2) {
                // 专用发票
                this.form = {
                    ...item,
                    radio: 2,
                    InvoiceType: 2
                }
            } else if (item.InvoiceType == 3) {
                // 专用发票
                this.form = {
                    ...item,
                    radio: 2,
                    InvoiceType: 3
                }
            }
            this.dialogVisible = true
        },
        // 提交发票
        submitForm(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (this.form.radio == 1) {
                        this.form.InvoiceType = 1
                    }
                    this.form.IsDefault = this.form.IsDefault
                    this.dialogVisible = false
                    changedInvoice(this.form).then(() => {
                        this.$message.success('操作成功')
                        this.$refs['ruleForm'].resetFields()

                        this.form = {
                            radio: 2,
                            InvoiceType: 2
                        }
                        this.init()
                    })
                } else {
                    return false
                }
            })
        },
        handleDefault(data) {
            data.IsDefault = true
            changedInvoice(data).then(() => {
                this.$message.success('操作成功')
                this.init()
            })
        },
        del(data) {
            this.$confirm('你确定要执行吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                removeInvoice({ ID: data.ID }).then(() => {
                    this.$message.success('删除成功')
                    this.init()
                })
            })
        },
        init() {
            getUserInvoice().then(res => {
                this.list = res.data
            })
        }
    }
}
</script>

<style lang="less" scoped>
.lable {
    margin: 5px;

    span {
        margin-left: 50px;
    }
}

.invoice-list-main {
    display: flex;
    justify-content: space-between;
}

.invoice-list-right {
    text-align: center;
    font-family: '微软雅黑';
    font-size: 12px;

    div {
        margin-top: 20px;
        cursor: pointer;
    }
}

.top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 5px;

    .title {
        font-size: 18px;
        font-family: 'ct';
    }

    .policy {
        font-size: 12px;
        color: #0097ba;
        margin-top: 10px;
        cursor: pointer;
    }
}

.invoice-list {
    padding: 20px;
    border: 1px solid #f4f4f4;
    margin: 20px;
    font-size: 13px;
    font-family: 'ct';
}

.active {
    background: rgba(27, 152, 170, 0.2);
    padding: 2px 10px;
    color: #1b98aa;
}
</style>
<style scoped>
.invoice-dialog >>> .el-dialog__header {
    background: #e5e5e5;
    padding: 10px;
    font-size: 14px;
}

.invoice-dialog-main {
    margin-top: 20px;
}

>>> .el-input__inner,
>>> .el-radio--small.is-bordered {
    border-radius: 0;
}

>>> .el-radio__input {
    display: none;
}

>>> .el-radio__input.is-checked {
    position: relative;
}

>>> .el-radio.is-bordered.is-checked {
    border-color: #dc2310;
}

>>> .el-radio__input.is-checked + .el-radio__label {
    color: black;
}

>>> .el-radio__label {
    padding: 0 10px;
}

>>> .is-checked::after {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    background-image: url('../../assets/invoice.png');
    background-size: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
}
</style>
